import { BREAKPOINTS_PX } from './device';
import { closeDialog, showModalDialog } from './dialog';

export const initHeader = () => {
	const header: HTMLElement | null = document.querySelector('[data-header]');
	const mobileMenu: HTMLDialogElement | null =
		document.querySelector('[data-mobile-menu]');
	const mobileMenuToggles: NodeListOf<HTMLButtonElement> | null =
		document.querySelectorAll('[data-mobile-menu-toggle]');

	if (!header || !mobileMenu || !mobileMenuToggles) {
		return;
	}

	const openMobileMenu = () => {
		showModalDialog(mobileMenu);
		header.setAttribute('data-mobile-menu-open', 'true');
		document.body.setAttribute('data-scroll-lock', 'true');
	};

	const closeMobileMenu = () => {
		closeDialog(mobileMenu);
		header.setAttribute('data-mobile-menu-open', 'false');
		document.body.setAttribute('data-scroll-lock', 'false');
	};

	mobileMenuToggles.forEach((toggle) => {
		toggle.addEventListener('click', () => {
			const isOpen = header.getAttribute('data-mobile-menu-open') === 'true';
			if (isOpen) {
				closeMobileMenu();
			} else {
				openMobileMenu();
			}
		});
	});

	// Obverve dialog state and update if closed via keyboard
	const observer = new MutationObserver((mutations) => {
		const menuOpen = mutations.some(
			(mutation) => mutation.attributeName === 'open' && mobileMenu.open,
		);

		if (menuOpen) {
			openMobileMenu();
		} else {
			closeMobileMenu();
		}
	});

	observer.observe(mobileMenu, { attributes: true });

	// Close mobile menu on resize
	window.addEventListener('resize', () => {
		if (window.innerWidth > BREAKPOINTS_PX.lg) {
			closeMobileMenu();
		}
	});
};
