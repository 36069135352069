// Sync with src/css/custom-media.css
export const MEDIA_QUERIES = {
	xxs: '(min-width: 20em)',
	xs: '(min-width: 23.4375rem)',
	sm: '(min-width: 26.75em)',
	md: '(min-width: 48em)',
	lg: '(min-width: 64em)',
	xl: '(min-width: 75rem)',
	xxl: '(min-width: 90em)',
	xxxl: '(min-width: 94.5em)',
	hover: '(hover: hover)',
};

export const BREAKPOINTS_PX = {
	xs: 320,
	sm: 394,
	md: 768,
	lg: 1024,
	xl: 1200,
	xxl: 1440,
	xxxl: 1512,
};

export const getPrefersReducedMotion = () =>
	window.matchMedia('(prefers-reduced-motion: reduce)').matches;

export const getIsMobileOs = (navigator: Navigator) => {
	const regex =
		/Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
	return regex.test(navigator.userAgent);
};
