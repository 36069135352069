// Fallback for browsers that don't have full <dialog> support
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog
export const hasDialogSupport = () => typeof HTMLDialogElement === 'function';

export const showModalDialog = (dialog: HTMLDialogElement) => {
	if (hasDialogSupport()) {
		dialog.showModal();
	} else {
		dialog.style.display = 'block';
	}
};

export const closeDialog = (dialog: HTMLDialogElement) => {
	if (hasDialogSupport()) {
		dialog.close();
	} else {
		dialog.style.display = 'none';
	}
};
